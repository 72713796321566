import { FC, memo, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { UserLoginBlock } from '@vakantiesnl/components/src/organisms/LoginBlock';
import { getCachedContentTemplateFromContentful } from '@vakantiesnl/services/src/contentfulTemplates';
import { LOGGED_IN_QUERY_KEY } from '@vakantiesnl/services/src/hooks/queries';
import { getWhoAmI } from '@vakantiesnl/services/src/user';
import { SSO_TOKEN_COOKIE } from '@vakantiesnl/services/src/util/authToken';
import { ContentTemplate } from '@vakantiesnl/types/src';
import Router from 'next/router';
import cookies from 'next-cookies';

import { GlobalPageProps, VaknlNextPage } from './_app';
import CustomError from './_error';
import { NextPreviewPageContext } from '../../@types/next-preview-page-context';
import DefaultLayout from '../layout';

type ErrorProps = {
	errorCode: number;
};

type RenderProps = GlobalPageProps & {
	content: ContentTemplate;
	errorCode: false;
};

type Props = ErrorProps | RenderProps;

const RenderLayout: FC<RenderProps> = ({ content }) => {
	const queryClient = useQueryClient();

	useEffect(() => {
		// When the user is able to visit the login page, we can assume they did not pass authentication,
		// so reset all user queries
		queryClient.resetQueries({ queryKey: [LOGGED_IN_QUERY_KEY] });
	}, [queryClient]);

	return <UserLoginBlock content={content} variant="page" />;
};

const MemoRenderLayout = memo(RenderLayout);

const MyVakantiesOverview: VaknlNextPage<Props> = (props) => {
	if (props.errorCode !== false) {
		return <CustomError statusCode={props.errorCode || 404} />;
	}

	return <MemoRenderLayout {...props} />;
};

MyVakantiesOverview.getInitialProps = async (ctx: NextPreviewPageContext): Promise<Props> => {
	const { res, req } = ctx;

	// Check if user is already logged in, if so then redirect to the account page
	if (req && res) {
		const token = cookies({ req })[SSO_TOKEN_COOKIE];
		const whoAmIRes = await getWhoAmI(token);

		if (whoAmIRes.status === 'success') {
			res.writeHead(302, { Location: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}` });
			res.end();
		}
	} else {
		const whoAmIRes = await fetch(`/api/user/who-am-i`);

		if (whoAmIRes.status === 200) {
			await Router.push(`/${process.env.NEXT_PUBLIC_USER_BASE_PATH}`);

			// Due to Router.push, this component should not end up here and returned props do not matter.
			// So typecast to avoid type errors
			return {} as Props;
		}
	}

	try {
		const content = await getCachedContentTemplateFromContentful('/login');

		return {
			errorCode: false,
			seo: content.seo,
			contentfulId: content.id,
			content,
		};
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Error getInitialProps on the Login page: ', err);

		if (res?.statusCode) {
			res.statusCode = 404;
		}

		return { errorCode: 404 };
	}
};

MyVakantiesOverview.Layout = DefaultLayout;

export default MyVakantiesOverview;
