import { MicroCopy } from '@vakantiesnl/types/src';
import { z } from 'zod';

import { MIN_PASSWORD_LENGTH } from '../../constants/form';

// Zod creates return type automatically based on setup
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getLoginSchema(microCopies: MicroCopy) {
	return z.object({
		email: z.string().email({ message: microCopies['register.errors.email'] }),
		password: z.string().min(MIN_PASSWORD_LENGTH, { message: microCopies['register.errors.password.minLength'] }),
	});
}

export type LoginFormValues = z.infer<ReturnType<typeof getLoginSchema>>;
