import { ReactNode, forwardRef, useMemo } from 'react';

import { Input, InputProps as MUIInputProps } from '@mui/base/Input';

import { useCustomStyles } from './Input.style';

export type InputProps = Omit<MUIInputProps, 'maxRows' | 'minRows' | 'multiline' | 'rows' | 'slots'> & {
	label: ReactNode;
	inputSize?: 'small' | 'medium';
	maxLength?: number;
};

const InputComponent = forwardRef<HTMLInputElement, InputProps>(({ maxLength, inputSize, ...props }, ref) => {
	const { classes, cx } = useCustomStyles();

	const slotProps = useMemo(
		() => ({
			root: {
				...props.slotProps?.root,
				className: cx(classes.root, {
					[classes.mediumRoot]: inputSize === 'medium',
					[classes.smallRoot]: inputSize === 'small',
				}),
			},
			input: {
				...props.slotProps?.input,
				className: cx(classes.input, {
					[classes.mediumInput]: inputSize === 'medium',
					[classes.smallInput]: inputSize === 'small',
				}),
				...(maxLength ? { maxLength } : {}),
			},
		}),
		[
			classes.input,
			classes.mediumInput,
			classes.mediumRoot,
			classes.root,
			classes.smallInput,
			classes.smallRoot,
			cx,
			maxLength,
			props.slotProps?.input,
			props.slotProps?.root,
			inputSize,
		],
	);

	return <Input<'input'> {...props} slotProps={slotProps} ref={ref} />;
});

InputComponent.displayName = 'InputComponent';

export { InputComponent };
