import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'error'>()((theme, _, classes) => ({
	root: {
		color: theme.input.message.color.default,
		font: theme.typography.bodySm,
		margin: 0,

		[`.${classes.error}&&`]: {
			color: theme.input.message.color.negative,
		},
	},
	error: {},
}));
