import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void>()((theme) => ({
	root: {
		margin: 0,
	},

	required: {
		color: theme.body.foreground.color.mandatory,
	},
}));
