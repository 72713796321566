import { FC, PropsWithChildren } from 'react';

import { useStyles } from './AccountContentWrapper.style';
import { Content } from '../../atoms/Content';
import { Typography } from '../../atoms/Typography';

type Props = PropsWithChildren<{ title: string }>;

export const AccountContentWrapper: FC<Props> = ({ children, title }) => {
	const { classes } = useStyles();

	return (
		<Content className={classes.content} fullHeight>
			<Typography variant="heading2Xl" as="h1" className={classes.header}>
				{title}
			</Typography>
			{children}
		</Content>
	);
};
