import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'start'>()((theme, _, classes) => ({
	root: {
		color: theme.input.prefix.color,
		font: theme.typography.bodyMd,
		gridArea: 'endAdornment',
		justifySelf: 'self-end',
	},
	icon: {
		color: theme.input.label.color,
	},
	start: {
		gridArea: 'startAdornment',
		justifySelf: 'stretch',

		[`+ .${classes.start}`]: {
			gridArea: 'prefixAdornment',
		},
	},
}));
