import { FC, useMemo } from 'react';

import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment';

import { useCustomStyles } from './InputAdornment.style';

export type InputAdornmentComponentProps = {
	/** Element to render */
	children: InputAdornmentProps['children'];
	/** Position of the adornment, before or after the input element */
	position: InputAdornmentProps['position'];
	/** Type of the adornment */
	type?: 'text' | 'icon';
};

const InputAdornmentComponent: FC<InputAdornmentComponentProps> = ({ children, position, type = 'text' }) => {
	const { classes, cx } = useCustomStyles();

	const inputAdornmentClasses = useMemo(() => ({ root: classes.root }), [classes]);

	return (
		<InputAdornment
			className={cx(type === 'icon' && classes.icon, position === 'start' && classes.start)}
			classes={inputAdornmentClasses}
			position={position}
			disableTypography
		>
			{children}
		</InputAdornment>
	);
};

export { InputAdornmentComponent };
