import { FC, useState, useMemo, useCallback, useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { InputAdornmentComponent } from '@vakantiesnl/components/src/atoms/InputAdornment';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { LoginFormValues, getLoginSchema } from '@vakantiesnl/services/src/schemas/user/loginSchema';
import { User } from '@vakantiesnl/types/src';
import { Url } from 'next/dist/shared/lib/router/router';
import { useForm } from 'react-hook-form';

import { useStyles } from './LoginForm.style';
import { Button } from '../../atoms/Button';
import { Divider } from '../../atoms/Divider';
import { Icon } from '../../atoms/Icon';
import { Link } from '../../atoms/Link';
import { TextFieldComponent } from '../../molecules/TextField';

type Props = {
	submitForm: (data: LoginFormValues) => Promise<void>;
	submitSocialLogin?: (provider: User.ProviderName) => Promise<void>;
	registerUrl: Url;
	forgotPasswordUrl: Url;
	gtmLabel: 'B2B' | 'B2C';
};

export const LoginForm: FC<Props> = ({ submitForm, registerUrl, submitSocialLogin, forgotPasswordUrl, gtmLabel }) => {
	const microCopies = useMicroCopyContext();
	const [error, setError] = useState<string | null>(null);
	const [isPasswordType, setIsPasswordType] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { classes, cx } = useStyles();

	const isSocialLoginEnabled = !!submitSocialLogin;

	const {
		handleSubmit,
		register,
		formState: { errors, dirtyFields },
	} = useForm<LoginFormValues>({
		mode: 'onSubmit',
		resolver: zodResolver(getLoginSchema(microCopies)),
	});

	const togglePasswordVisibility = useCallback(
		() => setIsPasswordType((currentIsPasswordType) => !currentIsPasswordType),
		[],
	);

	useEffect(() => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/authPage').then(({ trackOnLoginPageLoad }) => {
				track(trackOnLoginPageLoad(gtmLabel));
			}),
		);
	}, [gtmLabel]);

	const passwordInputProps = useMemo(
		() => ({
			endAdornment: isPasswordType ? (
				<InputAdornmentComponent position="end" type="icon">
					<span onClick={togglePasswordVisibility}>
						<Icon type="visibilityOff" className={classes.visibilityIcon} />
					</span>
				</InputAdornmentComponent>
			) : (
				<InputAdornmentComponent position="end" type="icon">
					<span onClick={togglePasswordVisibility}>
						<Icon type="visibilityOn" className={classes.visibilityIcon} />
					</span>
				</InputAdornmentComponent>
			),
		}),
		[classes.visibilityIcon, isPasswordType, togglePasswordVisibility],
	);

	const createSocialSignInHandler = useCallback(
		(providerName: User.ProviderName) => {
			if (!isSocialLoginEnabled) {
				return;
			}

			return async () => {
				setError(null);
				try {
					await submitSocialLogin(providerName);
				} catch (err) {
					if (err instanceof Error) {
						// If user closes sign in popup Firebase throws an error
						// But I think there is no need to show an error message to the user in this case
						// So ignore the case when user closes the sign in popup
						const errorMessage =
							err.message === 'auth/popup-closed-by-user' ||
							err.message === 'auth/cancelled-popup-request'
								? null
								: err.message;
						setError(errorMessage);
					}
				}
			};
		},
		[submitSocialLogin, isSocialLoginEnabled],
	);

	const onSubmit = useCallback(
		async (data: LoginFormValues) => {
			setIsLoading(true);
			setError(null);

			try {
				await submitForm(data);
			} catch (err) {
				setError(err instanceof Error ? err.message : 'Failed to login');
			} finally {
				setIsLoading(false);
			}
		},
		[submitForm],
	);

	return (
		<>
			{isSocialLoginEnabled && (
				<>
					<div className={classes.socials}>
						<Button
							leadingIcon="facebook"
							onClick={createSocialSignInHandler('facebook')}
							variant="secondary"
							className={classes.socialButton}
						>
							{microCopies['auth.login.button.loginWithFacebook']}
						</Button>
						<Button
							leadingIcon="google"
							onClick={createSocialSignInHandler('google')}
							variant="secondary"
							className={classes.socialButton}
						>
							{microCopies['auth.login.button.loginWithGoogle']}
						</Button>
					</div>
					<div className={classes.dividers}>
						<Divider className={classes.socialDivider} noMargins />
						<span className={classes.or}>{microCopies['auth.divider.or']}</span>
						<Divider className={classes.socialDivider} noMargins />
					</div>
				</>
			)}
			<form className={classes.form} onSubmit={handleSubmit(onSubmit)} data-cy="login" noValidate>
				<TextFieldComponent
					autoComplete="email"
					id="email"
					type="email"
					label={microCopies['login.input.email']}
					className={classes.filedRow}
					error={errors.email !== undefined}
					helperText={errors?.email?.message}
					showErrorAdornment={!!errors.email}
					showValidAdornment={dirtyFields.email && errors.email === undefined}
					{...register('email')}
				/>
				<TextFieldComponent
					autoComplete="password"
					id="password"
					type={isPasswordType ? 'password' : 'text'}
					label={microCopies['login.input.password']}
					InputProps={passwordInputProps}
					error={errors.password !== undefined}
					helperText={errors?.password?.message}
					{...register('password')}
				/>
				{error && (
					<p className={classes.error} id="error">
						{microCopies[error] ?? microCopies['login.auth.unexpected-error']}
					</p>
				)}
				<Link href={forgotPasswordUrl} className={cx(classes.link, classes.forgotPswdLink)}>
					{microCopies['login.link.forgot']}
				</Link>
				<Button type="submit" variant="primary" className={classes.loginButton} disabled={isLoading}>
					{microCopies['auth.login.loginButton']}
				</Button>
			</form>
			<Link href={registerUrl} className={classes.link}>
				{microCopies['auth.login.link.noAccountYet']}
			</Link>
		</>
	);
};
