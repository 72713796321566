import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	label: {
		marginBottom: theme.spacing.tiny,
	},
	helperText: {
		marginTop: theme.spacing.tiny,
	},
	endAdornmentError: {
		color: theme.input.foreground.negative.color,
	},
	endAdornmentValid: {
		color: theme.input.foreground.positive.color,
	},
}));
