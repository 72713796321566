import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	header: {
		marginBottom: theme.spacing.medium,

		[getFromBreakpoint('md')]: {
			marginBottom: theme.spacing.large,
		},
	},
	content: {
		marginTop: theme.spacing.medium,
		marginBottom: theme.spacing.medium,

		[getFromBreakpoint('md')]: {
			marginTop: theme.spacing.large,
			maxWidth: theme.size.medium,
			marginBottom: theme.spacing.large,
		},
	},
}));
