import { forwardRef } from 'react';

import { FormControl, FormControlProps } from '@mui/base/FormControl';

import { useCustomStyles } from './FormControl.style';

const FormControlComponent = forwardRef<HTMLDivElement, FormControlProps>(({ children, ...rest }, ref) => {
	const { classes } = useCustomStyles();
	return (
		<FormControl className={classes.root} {...rest} ref={ref}>
			{children}
		</FormControl>
	);
});

FormControlComponent.displayName = 'FormControlComponent';

export { FormControlComponent };
